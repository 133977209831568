<template>
    <section id="dashboard-fiordo-potencial-stock">
        
        <b-row>
            <b-col cols="6">
                <bruna-element-loader :dataUrl="chart0">
                </bruna-element-loader>
                <bruna-element-loader :dataUrl="chart1">
                </bruna-element-loader>
            </b-col>
            <b-col cols="6">
                <ag-grid-table
                :configUrl="config"
                :dataUrl="data"
                :editUrl="edit"
                ></ag-grid-table>
            </b-col>
        </b-row>

        <b-row>
            <b-col cols="3" v-for="(item, index) in itemsData" :key="index">
                <b-card class="text-center">
                  
                  <div class="heading-elements">
                    <ul class="list-inline col text-right">
                      <li>
                        <feather-icon
                          icon="HelpCircleIcon"
                          
                          size="20"
                        />
                      </li>
                    </ul>
                  </div>

                  <b-avatar class="mb-1" :variant="item.color" size="45">
                    <feather-icon size="21" :icon="item.icon" />
                  </b-avatar>
                  <div class="truncate">
                    <h5 class="mb-25 font-weight-bolder">{{ item.title }}</h5>
                    <span>{{ item.subtitle }}</span>
                  </div>
                </b-card>
            </b-col>
        </b-row>

        <b-row>
            <b-col>
                <bruna-element-loader :dataUrl="chart2">
                </bruna-element-loader>
            </b-col>
        </b-row>
        
        <b-row>
          <b-col>
            <b-card>
            <b-button
            variant="primary"
            class="float-right"
            @click="goToBack"
            size="lg"
            >
            Volver Histórico de Planificaciones
            </b-button>
            </b-card>
          </b-col>
        </b-row>

    </section>
</template>
<script>
import BrunaElementLoader from '@/views/brunacomponents/BrunaElementLoader.vue'
import AgGridTable from '@/views/brunacomponents/ag-grid-table/AgGridTable.vue'
import RepeatStatistics from "@/views/clients/fiordo/components/RepeatStatistics"
import{BRow, BCol, BButton } from "bootstrap-vue";
import useApiServices from '@/services/useApiServices.js';
import router from '@/router';
import axios from "@axios";
export default {

    mounted()
    {
      axios.get(useApiServices.potencialStockStatistics)
      .then((response) =>
      {
          this.itemsData = response.data;
      })
      .catch((error) =>
      {

      });
    },

    data()
    {
        return {
            chart0: useApiServices.potencialStockChart0 + "?centro=all",
            chart1: useApiServices.potencialStockChart1 + "?centro=all",
            chart2: useApiServices.potencialStockChart2 + "?centro=all",
            config: useApiServices.potencialStockConfig,
            data: useApiServices.potencialStockData,
            edit: useApiServices.potencialStockEdit,
            itemsData: []
        };
    },
    components: {
        BRow,
        BCol,
        BButton,
        AgGridTable,
        BrunaElementLoader,
        RepeatStatistics
    },

    methods: {
      goToBack(){
        router.push({ name: 'dashboard-fiordo-historico-planificacion' })
      }
    },
};
</script>

<style scoped>
.titulo {
  margin-top: 5%;
}
</style>

<style lang="scss">
.card {
  .card-title {
    margin-bottom: 1.5rem !important;
  }

  ::v-deep .card-header {
    .heading-elements {
      position: static;
      cursor: inherit;

      .list-inline {
        display: block;

        li {
          a {
            padding: 0;
          }

          &:not(:last-child) {
            margin-right: 1rem;
          }
        }
      }
    }
  }
}
</style>